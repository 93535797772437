import React from "react"
// import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import { PageLayout } from "../components/layouts"
// import Image from "../components/image"
import SEO from "../components/seo"
import { IndividualSurvey } from "../components/survey"

import TextBlock from "../components/textBlock"
import TextImageBlock from "../components/textImageBlock"
import SingleColumnBlock from "../components/singleColumnBlock"
import PageWrapper from "../components/pageWrapper"

import HeroSlider from "../components/heroSlider"
import ParallaxSection from "../components/parallaxSection"

import Blockquote from "../components/blockquote"

import {
  ContactForm,
  FormWrapper,
  RequestMoreInfo,
  RequestQuote,
} from "@components/Forms"

import {
  RequestMoreInfoButton,
  ContactUsTodayButton,
  DownloadProductBrochureButton,
} from "../components/buttons"

import { QuotePanel } from "../components/quotes"

const LaminatedGlass = () => {
  const data = useStaticQuery(graphql`
    query {
      parallax: file(relativePath: { eq: "AdobeStock_250630318.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <PageLayout heroTitle="LAMINATED GLASS" heroBackgroundImage="/images/laminated-glass/header-laminated.jpg">
      <SEO
        title="Decorative Laminated Glass | ArtVue Glass"
        ogDescription="ArtVue Glass offers custom decorative laminated glass for commercial or residential applications. Our decorative glass panels are available in 1/2, 3/4, 1 inch or any other thickness you need. Customize it to fit your needs, from privacy to beauty!"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>Create Unique Designs With Added Functional Benefits</h2>
            <p>
              In glass skylights, sunspaces, sloped glazing installations, and curtainwalls, glass breakage is always possible. Without laminated glass, that could mean a significant safety problem caused by falling glass. Laminated glass remains intact when broken, reducing the potential for injury. As a result, many building codes worldwide require laminated glass for overhead glazing.
            </p>
            <p>
              Laminated glass by ArtVue Glass is available in various forms for decorative commercial and residential glazing applications. It is possible to laminate glass with different flat glass types, patterned glass, back painted glass, and various decorative or structural interlayers in combination with one another or alone.
            </p>
                        <FormWrapper
              modal
              buttonProps={{
                content: "Request More Info",
                size: "tiny",
                icon: "calendar",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
          </div>
        }
        textRight={
          <div>
            <img src="/images/laminated-glass/laminated-glass.jpg" alt="Laminated Glass" />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>Features and Benefits of Decorative Laminated Glass</h2>
            <ul>
              <li><strong>Safety</strong></li>
              <li><strong>Custom</strong></li>
              <li><strong>Versatility</strong></li>
              <li><strong>UV Protection</strong></li>
              <li><strong>Sound Dampening</strong></li>
              <li><strong>Structural Rigidity</strong></li>
            </ul>
          </div>
        }
        textRight={
          <div>
            <img src="/images/laminated-glass/architectural-canopy-laminated-decorative-glass.jpg" alt="Architectural Canopy Laminated Decorative Glass" />
          </div>
        }
      />
     <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>1. Safety Laminated Glass</h3>
            <p>
              Laminated glass is a form of safety glass that holds together when broken. In case of breakage, glass fragments remain firmly bonded to the interlayer, minimizing the risk of injuries. The most common construction is two pieces of glass bonded together by an interlayer. Laminated glass offers many possibilities and benefits compared to a single piece of monolithic glass, especially for decorative considerations.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/laminated-glass/decorative-laminated-waterview-pattern-glass.jpg" alt="Laminated Glass Waterview Pattern" />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h3>2. Customizable Decorative Laminated Glass</h3>
            <p>
              Create unique designs using different textured glass laminated together, or textured glass laminated to clear glass, or laminate your favorite glass to a piece of mirror. Using cutting-edge technology, ArtVue presents custom laminated glass; your way to personalizing your home and office interiors for a sleek, elegant look. Choose your texture, color, and level of transparency and take your ambiance to the next level. Laminated glazing can be manufactured as annealed, heat-strengthened, and tempered, as well as combining pattern glass, tinted, back painted glass, and decorative interlayers.
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h3>3. A Barrier Against Harmful uv Light</h3>
            <p>
              The primary cause of deterioration and fading of furnishings and pictures is the chemical reaction caused by short-wavelength UV radiation. Laminated glass interlayers screen out almost all these damaging rays.
            </p>
            <p>
              Beyond the benefits of safety glass, laminated glass also protects against UV light and reduces unwanted noise.
            </p>
            <p>
              The sun's UV rays are proven to damage everything from furniture, flooring, and your skin. Laminated glass blocks more than 99% of the UV radiation while allowing the most visible light (using clear glass).
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h3>4. Dampens Noise</h3>
            <p>
               In recent years due to the high development of our society the noise level has tripled and with this so have architectural requirements. Laminated glass gives a higher level of soundproofing which makes it an excellent product for noisy environments and for double glazing. With its double thickness of glass and plastic interlayer, laminated glass also makes it an effective way to reduce unwanted noise compared to monolithic glass panes of the same thickness.
            </p>
            <h3>5. Unparraled Structural Rigidity</h3>
            <p>
              Structural engineers recognize the performance benefits of a more rigid laminated structure for facades, overhead glazing, railings, doors, and partitions. Today, many modern architectural buildings use SentryGlas® to create new and less framed glass structures.
            </p>
            <p>
              ArtVue Glass is a certified SentryGlas® laminator. SentryGlas® ionoplast interlayer is five times stronger and up to 100 times stiffer than conventional PVB laminating materials. With this kind of strength, glass can be a more active structural element, opening design possibilities that did not exist before. Besides its strength, SentryGlas® ionoplast interlayer retains its clarity – even after years of service. Unlike other interlayers, SentryGlas® ionoplast interlayer is much less vulnerable to moisture exposure or yellowing over time.
            </p>
          </div>
        }
      />
      <PageWrapper color="grey">
     <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>An Overview of the Different Types of Decorative Laminated Glass</h2>
            <h3>Tempered versus Laminated Glass</h3>
            <p>
              Glass is tempered when it is heated up and cooled very rapidly, known as quenching. Quenching cools the outer surfaces of the glass much more quickly than the center. As the center of the glass cools, it tries to pull back from the outer surfaces. As a result, the center remains in tension, and the outer surfaces go into compression, which gives tempered glass its strength. [Insert diagram here] When tempered glass is broken, it breaks into small pieces instead of large shards. Tempered glass is also referred to as toughened glass.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/laminated-glass/annealed-tempered-strengthened-glass-types.jpg" alt="Tempered versus Laminated Glass" />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <p>
              Laminated glass, consisting of two pieces of glass and an interlayer, is an exceptionally durable, high-performance glazing product, designed to remain integral in the opening should the glass break. Laminated glass can be made up of annealed glass or tempered glass, depending on the application.  
            </p>
            <h3>Tempered Laminated Glass</h3>
            <p>
              For those who want to take the structural rigidity and strength a notch higher, the tempered laminated glass is one of the strongest forms of laminated glass available in the market.
            </p>
            <p>
              Individual panes of glass are rapidly cooled in a process called quenching after heating. After laminating together individual panes of tempered glass, tempered laminated glass emerges to be up to 10 times stronger than regular glass. With the strength of tempering and the safety feature of laminated glass; you finally get a blend you have been waiting for.
            </p>
            <p>
              There are several advantages of using tempered laminated glass. If broken, the tempered glass breaks into small pieces, and since the tempered glass is laminated, these small pieces remain mostly in place.  This factor is what makes this form of glass a perfect fit for high-rise railings, skywalks, skylights, bathroom door panels and wall cladding.
            </p>
            <p>
              Get the aesthetics you want, without compromising on safety and strength – what more could one desire to uplift their property’s ambiance?Tempered Laminated Glass
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>Different Types of Laminated Glass Interlayers</h3>
            <p>
              Laminated glass also utilizes different types of interlayers, such as PVB (polyvinyl butyral), EVA (ethylene-vinyl acetate), SentryGlas ionoplast, and TPU (thermoplastic polyurethane).
            </p>
            <ul>
              <li>PVB is normally used in automotive and shaped glass applications due to its pliability. </li>
              <li>On the other hand, the most used interlayer for many architectural and decorative flat glass applications is EVA due to its better optics with transparency values close to extra clear glass and better resistance to moisture than PVB. </li>
              <li>SentryGlas ionoplast interlayer is also used for architectural and decorative applications due to its high structural rigidity, clarity, and moisture resistance. </li>
              <li>TPU is the best choice for Bullet-Resistant Glass (BRG) and for so called E-Glass (Smart Glass).</li>
            </ul>
          </div>
        }
        textRight={
          <div>
            <img src="/images/laminated-glass/eva-pvb-comparison.png" alt="EVA PVB Comparison" />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>Colored Laminated Glass</h3>
            <p>
              Redefine your property’s interiors with sleek, architectural laminated glass. Choose your level of density from transparent to translucent or completely solid opaque. Pick your favorite hue to match the area of purpose. Like washrooms go with frosted, white laminated glass; accent walls can be revamped with shimmering gold or bright yellow colored laminated glass. In addition, glass can be combined with back painted glass, obscure pattern glass, or decorative interlayers to match your design vision.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/laminated-glass/colored-laminated-glass.jpg" alt="Colored Laminated Glass" />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>Textured Laminated Glass</h3>
            <p>
              Enhance the elegance of seemingly mundane rooms with solid, neon, or bright textured laminated glass. Sturdy enough to withstand incredible pressure, yet beautiful enough to redefine the feel of the room, textured laminated glass boasts a smooth finish and seamless installation. Choose between serene rain-patterned glass or rugged wood grain, or a plethora of other textures, and enter the world of modern architecture.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/laminated-glass/textured-laminated-glass.jpg" alt="Textured Laminated Glass" />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>Tinted Laminated Glass</h3>
            <p>
              Solids work in bright, open, airy spaces. But when you are looking to enhance the feel of your relatively somber property, it is time to indulge in pops of color. Our tinted laminated glass allows you to play around with a plethora of colors without compromising on your safety. The colored interlayers add a change of hue to the glass, while the power of lamination ensures it remains shatter-proof and Grade A safe. 
            </p>
            <p>
              Commonly used in windows, doors, partitioning, backsplashes, and shopfronts; tinted laminated glass adds a touch of modernity to the architecture with hues that last and a look that mesmerizes. 
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/laminated-glass/tinted-laminated-glass.jpg" alt="Tinted Laminated Glass" />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>The Most Common Decorative Laminated Color … White!</h2>
            <h3>White Laminated Glass</h3>
            <p>
              White laminated glass is one of the most widely used forms of architectural decorative laminated glass. Its versatility and ease of use make a common selection and favorite addition in modern properties.  
            </p>
            <p>
              From a design perspective, white is ubiquitously viewed as a neutral and clean color and is therefore used in a myriad of places such as kitchens, bathrooms, hallways, elevators, spas, hotels, and hospitals.
            </p>
            <p>
              Does the idea of a white, secure, beautiful glass adorning your property make you curious to know more? There are many options amid white laminated glass to choose from. Read on! 
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h4>Solid White Laminated Glass</h4>
            <p>
              Add a soft, milky touch to your property by choosing solid white laminated glass by ArtVue Glass. Impeccably designed to block light, the solid white glass offers a profound sense of security while ensuring you get to enjoy the sheer elegance of glass around you.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/laminated-glass/solid-white-laminated-glass.jpg" alt="Solid White Laminated Glass" />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h4>White Opaque Laminated Glass</h4>
            <p>
              Indulge in the beauty of contrasts to set your place apart. The usage of white opaque laminated glass against marble floors invokes a feel of blended in walls and flooring while using it in contrast with dark colors adds a sense of vibrancy to the home. Secure from breakage and falling, the glass gives a modern look and enhances the aesthetics of both commercial and residential buildings.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/laminated-glass/opaque-white-laminated-glass.jpg" alt="White Opaque Laminated Glass" />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h4>White Translucent Laminated Glass</h4>
            <p>
              Featuring a brilliant white interlayer and only allowing for partial visibility, the white translucent laminated glass is a way to breathe modernity into private and confidential property spaces. With a stained or frosted appearance, the white blends in with the atmosphere, creating airy vibes and works best in washrooms, meeting rooms, and other areas in need of privacy.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/laminated-glass/white-translucent-laminated-glass.jpg" alt="White Translucent Laminated Glass" />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>Frosted Laminated Glass</h3>
            <p>
              Frosted laminated glass has the appearance of sandblasted glass or frosted glass. It is translucent laminated glass that is becoming more widely used for decorative and screening purposes. You can use in interior building and exterior buildings.  
            </p>
            <p>
              Weatherproof and moisture-resistant, frosted laminated glass filters sunny glares making it the perfect option for overhead glass canopies, skylights, railings, and in kitchen and bathrooms.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/laminated-glass/white-frosted-laminated-glass.jpg" alt="Frosted Laminated Glass" />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>Laminated Glass Applications</h3>
            <h4>Laminated Glass Railing</h4>
            <p>
              Glass railings have prime importance in domestic and commercial applications. Yet, with the risk of breakage, regular glass does not fit the security criteria. Choose ArtVue’s laminated glass railing for a modern outlook to your property, while maintaining high-security standards. Shatterproof, and able to withstand large amounts of pressure (even bullets), the inner layer of vinyl prevents shards of glass from shattering or falling in case of breakage.
            </p>
            <p>
              Available in square and rectangle shapes, this laminated glass railing can be customized to fit your desired size, and perfectly complements balconies, bridges, patios, and stairways. 
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/laminated-glass/laminated-glass-railing.jpg" alt="Laminated Glass Railing" />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h4>Laminated Glass Floor</h4>
            <p>
              Take your interiors to the next level with laminated safety glass floors. Thick, heavy, and strong enough to carry weight, these glass panels have been secured with the SentryGlas® laminator and contain anti-slip properties to ensure a good grip of those walking on it. 
            </p>
            <p>
              Create entire floors of glass, add a glass bridge from one commercial building to the next, or go minimalist yet elegant by adding smaller rectangles in existing wooden or concrete floors. The additions will elevate your outlook to present a feeling of modernity and class for all viewers. 
            </p>
            <p>
              Choose your thickness, hue, and size, and let ArtVue take care of the rest.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/laminated-glass/laminated-glass-floor.jpg" alt="Laminated Glass Floor" />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h4>Laminated Glass Panels</h4>
            <p>
              The search for classy interior ideas ends with the chic and stylish laminated glass panels at ArtVue. Add them as windows, use them as artistic skylights, or turn your kitchen around with laminated glass panel backsplashes. These panels diffuse light, limit UV rays, and keep your interior temperatures maintained.
            </p>
            <p>
              Do you have a specific form of laminated glass panel in mind? We customize according to your preferences! Simply choose your size, shape, thickness, transparency, and color; and we will make it happen. 
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/laminated-glass/laminated-glass-panels.jpg" alt="Laminated Glass Panels" />
          </div>
        }
      />

      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Laminated Glass Mirror</h4>
            <p>
              When hoping to up your interior game, especially in smaller spaces, choosing a good mirror becomes imperative. Fortunately, laminated glass mirrors are here to help you do just that. 
            </p>
            <p>
              Featuring a satin-like finish, smooth edges, lightweight body, and scratch-free surface; laminated glass mirrors are redefining the mirror industry. With easy installation and assurance of safety, you can install these anywhere in your home or commercial spaces. From washroom mirrors to those located right above consoles, add one mirror to your abode or create entire glass mirror walls to give a spacious feel. 
            </p>
            <p>
              With the clear, safe, laminated glass mirror, the sky is the limit to your creativity; and ArtVue is the means to your dreams!
            </p>
            <p>
              With all these options, you may be asking yourself, where do I begin? With our deep experience and knowledgeable staff, we can guide you through the various options depending on your design intent, requirements, and needs. 
            </p>
                        <FormWrapper
              modal
              buttonProps={{
                content: "Contact Us Today",
                size: "tiny",
                icon: "phone",
              }}
            >
              <ContactForm />
            </FormWrapper>
          </div>
        }
      />
      </PageWrapper>
    </PageLayout>
  )
}

export default LaminatedGlass
